.block {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  cursor: grab;
}

.block:active {
  cursor: grabbing;
}

.sliderContainer {
  position: relative;
  text-align: center;
  width: 8.266667rem;
  height: 1.066667rem;
  line-height: 1.066667rem;
  margin-top: 0.4rem;
  background: #f7f9fa;
  color: #45494c;
  border: 0.026667rem solid #e4e7eb;
}

.sliderContainer_active .slider {
  height: 1.013333rem;
  top: -0.026667rem;
  border: 0.026667rem solid #1991fa;
}

.sliderContainer_active .sliderMask {
  height: 1.013333rem;
  border-width: 0.026667rem;
}

.sliderContainer_success .slider {
  height: 1.013333rem;
  top: -0.026667rem;
  border: 0.026667rem solid #52ccba;
  background-color: #52ccba !important;
}

.sliderContainer_success .sliderMask {
  height: 1.013333rem;
  border: 0.026667rem solid #52ccba;
  background-color: #d2f4ef;
}

.sliderContainer_success .sliderIcon {
  background-position: 0 -0.693333rem !important;
}

.sliderContainer_fail .slider {
  height: 1.013333rem;
  top: -0.026667rem;
  border: 0.026667rem solid #f57a7a;
  background-color: #f57a7a !important;
}

.sliderContainer_fail .sliderMask {
  height: 1.013333rem;
  border: 0.026667rem solid #f57a7a;
  background-color: #fce1e1;
}

.sliderContainer_fail .sliderIcon {
  top: 0.373333rem;
  background-position: 0 -2.186667rem !important;
}

.sliderContainer_active .sliderText,
.sliderContainer_success .sliderText,
.sliderContainer_fail .sliderText {
  display: none;
}

.sliderMask {
  position: absolute;
  left: 0;
  top: 0;
  height: 1.066667rem;
  border: 0 solid #1991fa;
  background: #d1e9fe;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.066667rem;
  height: 1.066667rem;
  background: #fff;
  box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.3);
  transition: background 0.2s linear;
  cursor: pointer;
  cursor: grab;
}

.slider:active {
  cursor: grabbing;
}

.slider:hover {
  background: #1991fa;
}

.sliderIcon {
  position: absolute;
  top: 0.4rem;
  left: 0.346667rem;
  width: 0.373333rem;
  height: 0.32rem;
  background: url(../assets/img/icon_light.4353d81.png) 0 -0.346667rem;
  background-size: 0.853333rem 14.506667rem;
}

.slider:hover .sliderIcon {
  background-position: 0 0;
}

.refreshIcon {
  position: absolute;
  right: 0.133333rem;
  top: 0.133333rem;
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  background: url(../assets/img/icon_light.4353d81.png) 0 -6.213333rem;
  background-size: 0.853333rem 14.506667rem;
}

.refreshIcon:hover {
  background-position: 0 -7.093333rem;
}

.loadingContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 8.266667rem;
  height: 4.133333rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.373333rem;
  color: #45494c;
  z-index: 2;
  background: #edf0f2;
}

.loadingIcon {
  width: 0.853333rem;
  height: 0.853333rem;
  margin-bottom: 0.266667rem;
  background: url(../assets/img/icon_light.4353d81.png) 0 -8.853333rem;
  background-size: 0.853333rem 14.506667rem;
  animation: loading-icon-rotate 0.8s linear infinite;
}

@keyframes loading-icon-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}